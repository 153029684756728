<template>
  <!--
    The view for the Employee Worklog Report generation
  -->
  <Portlet icon="book">
    <LoadingPlaceholder v-if="reportLoading" />
    <template v-if="reportLoading && reportData">
      <div class="progress mt-3 mb-5">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{ width: reportData.percentage + '%' }"
          :aria-valuenow="reportData.percentage"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ reportData.percentage + "%" }}
        </div>
      </div>
    </template>
    <template v-else>
      <template>
        <Multiselect
          id="accountingConfigs"
          v-model="selectedAccountingConfigs"
          tag-placeholder="Not found"
          placeholder="All Configs"
          :options="accountingConfigs"
          select-label=""
          :multiple="true"
          :taggable="true"
          :hide-selected="true"
          :searchable="true"
          :close-on-select="true"
          label="accountingIssueKey"
          track-by="id"
          class="mb-4"
        />
        <form>
          <div class="form-group col-12 mt-3">
            <label>{{ $t("from") }}</label>
            <date-picker
              v-model="selectedDateFrom"
              :editable="true"
              :clearable="false"
              type="date"
              lang="en"
              format="DD.MM.YYYY"
              value-type="date"
              :first-day-of-week="1"
              class="ml-3"
            />
            <label class="ml-4">{{ $t("to") }}</label>
            <date-picker
              v-model="selectedDateTo"
              :editable="true"
              :clearable="false"
              type="date"
              lang="en"
              format="DD.MM.YYYY"
              value-type="date"
              :first-day-of-week="1"
              class="ml-3"
            />
          </div>
          <button
            slot="button"
            class="btn btn-primary mt-4 mb-5"
            :disabled="!selectedDateFrom || !selectedDateTo"
            type="button"
            @click="generateIssues()"
          >
            <font-awesome-icon
              class="mr-2"
              icon="file-alt"
            />Generate Issues
          </button>
          <button
            slot="button"
            class="btn btn-primary mt-4 mb-5 ml-4"
            type="button"
            :disabled="reportLoading"
            @click="getReportProgress()"
          >
            <font-awesome-icon
              class="mr-2"
              icon="file-alt"
            />Get Last Report
          </button>
        </form>
      </template>
      <template v-if="reportData && reportData.error === 'missingFields'">
        <div>
          <p
            v-for="(fields, key) in reportData.errorValue"
            :key="key"
            style="color: red"
            class="mb-2"
          >
            <b>
              <a
                :href="jiraHref(key)"
                target="_blank"
                class="alt-primary-color"
              >
                {{ key }}
              </a>
            </b>
            is missing these fields: <b>{{ fields.join(", ") }}</b>
          </p>
          <p style="font-weight: bold">
            Issues were not created. Please add the missing values and rerun the
            the task to create all issues.
          </p>
        </div>
      </template>
      <template v-else-if="reportData && reportData.error === 'missingIssues'">
        <div>
          <p
            style="color: red"
            class="mb-2"
          >
            {{ reportData.errorMessage }}
          </p>
          <p style="font-weight: bold">
            Issues were not created. Please remove the missing issues in Jira
            and rerun the the task to create all issues.
          </p>
        </div>
      </template>
      <template v-else-if="reportData && reportData.value">
        <div>
          <p
            v-for="(issue, key) in reportData.value"
            :key="`issue-${key}`"
            :style="{ color: issue.hasError ? 'red' : null }"
            class="mb-2"
          >
            <b>
              <a
                :href="jiraHref(issue.mainKey)"
                target="_blank"
                class="alt-primary-color"
              >
                {{ issue.mainKey }}
              </a>
            </b>
            <span v-if="issue.hasError">
              sub task was not created due to an error
            </span>
            <span v-else>
              subtask
              <a
                :href="jiraHref(issue.subKey)"
                target="_blank"
                class="alt-primary-color"
              >
                {{ issue.subKey }}
              </a>
              was created.
            </span>
          </p>
        </div>
      </template>
    </template>
    <div class="d-flex flex-column">
      <div class="d-flex">
        <h4>{{ $t("configuration") }}</h4>
        <button
          class="btn btn-sm btn-primary float-right mt-2 mb-2"
          :style="'margin-left: auto;'"
          @click="showSidebarAdd"
        >
          <div class="d-flex">
            <font-awesome-icon
              class="mr-1"
              icon="plus"
            />
            <span>
              {{ $t("add") }}
            </span>
          </div>
        </button>
      </div>
      <AccountingConfigList
        ref="accountingConfigList"
        @setAccountingConfigs="setAccountingConfigs"
      />
    </div>
  </Portlet>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { authenticationMixin } from "@/mixins/authenticationMixin";
import { urlParseMixin } from "@/mixins/urlParseMixin";
import { mapGetters } from "vuex";

export default {
  name: "AccountingSupportInvoiceView",
  components: {
    DatePicker,
    AccountingConfigList: () =>
      import("@/components/Accounting/AccountingConfigList.vue"),
    Multiselect: () => import("vue-multiselect"),
  },
  mixins: [authenticationMixin, urlParseMixin],
  metaInfo() {
    return {
      title: this.$t("accounting.supportInvoice"),
    };
  },
  data() {
    return {
      selectedDateFrom: null,
      selectedDateTo: null,
      reportInterval: null,
      reportData: null,
      accountingConfigs: [],
      selectedAccountingConfigs: [],
      reportLoading: false,
    };
  },
  computed: {
    ...mapGetters(["store_getJiraUrl"]),
    userEmail() {
      var user = this.authenticationGetUser();
      if (
        user &&
        user.details &&
        user.details.profile &&
        user.details.profile.email
      ) {
        return user.details.profile.email;
      }
      return null;
    },
  },
  beforeDestroy() {
    this.cancelInterval();
  },
  methods: {
    jiraHref: function (id) {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${id}`);
    },
    setAccountingConfigs(list) {
      this.accountingConfigs = list;
      this.selectedAccountingConfigs = this.accountingConfigs.filter((x) =>
        this.selectedAccountingConfigs.some((s) => s.id === x.id)
      );
    },
    createInterval() {
      this.reportLoading = true;
      this.reportData = { percentage: 0 };
      this.reportInterval = window.setInterval(() => {
        this.getReportProgress();
      }, 2000);
    },
    cancelInterval() {
      this.reportLoading = false;
      window.clearInterval(this.reportInterval);
      this.reportInterval = null;
    },
    getReportProgress() {
      this.axios
        .get(
          `/Issue/GetAccountSupportInvoiceIssuesReportData?user=${this.userEmail}`
        )
        .then((response) => {
          if (response.status == 200 && response.data) {
            this.reportData = response.data;
            if (this.reportData.value) {
              this.cancelInterval();
            } else if (this.reportData.error) {
              this.cancelInterval();
            }
          } else {
            this.reportData = null;
          }
        });
    },
    async generateIssues() {
      var req = {
        user: this.userEmail,
        startDate: this.selectedDateFrom,
        endDate: this.selectedDateTo,
        ids: this.selectedAccountingConfigs.map((x) => x.accountingIssueKey),
      };

      await this.axios
        .post(`/Issue/CreateAccountSupportInvoiceIssues`, req)
        .then(() => {
          this.createInterval();
        });
    },
    showSidebarAdd() {
      this.$refs.accountingConfigList.showSidebarAdd();
    },
    reloadCollectionList() {
      this.$refs.accountingConfigList.reloadApplicationSettings();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
